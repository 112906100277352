import axios from "./Axios";
export const personalDetailsService = (data) => {
  return axios.post("kansas/franchise_enquiry/personal_details", data);
};

export const stateService = (data) => {
  return axios.post("kansas/franchise_enquiry/state_lists", data);
};

export const cityService = (data) => {
  return axios.post("kansas/franchise_enquiry/cities_lists", data);
};

export const listShopCustomerService = (data, page, size) => {
  return axios.post(
    `kansas/franchise_enquiry/list_shop_customer?page=${page}&size=${size}`,
    data
  );
};

export const productListService = (data, page, size) => {
  return axios.post(
    `kansas/products/list_products?page=${page}&size=${size}`,
    data
  );
};

export const ProductCategoryService = (data) => {
  return axios.post("kansas/category/list_category", data);
};

export const viewProductService = (data) => {
  return axios.post("kansas/products/view_products", data);
};

// Authentication

export const loginService = (data) => {
  return axios.post("kansas/login/access-token", data);
};

export const loginValidateService = (data) => {
  return axios.post("kansas/login_validate");
};
export const sendOTPService = (data) => {
  return axios.post("kansas/send_otp", data);
};

export const resetPasswordService = (data) => {
  return axios.post("kansas/reset_password", data);
};

export const verifyOtpService = (data) => {
  return axios.post("kansas/verify_otp", data);
};

export const ChangePasswordService = (data) => {
  return axios.post("kansas/change_password", data);
};

// cart
export const addtocartService = (data) => {
  return axios.post("kansas/products/add_to_cart", data);
};

export const cartListService = (data, page, size) => {
  return axios.post(
    `kansas/products/list_cart?page=${page}&size=${size}`,
    data
  );
};

export const listCustomerWishlistService = (data, page, size) => {
  return axios.post(
    `kansas/customer_wishlist/list_customer_wishlist?page=${page}&size=${size}`,
    data
  );
};

export const removeWishlistService = (data) => {
  return axios.delete(`kansas/products/remove_wishlist/${data}`);
};

export const deleteCartListService = (data) => {
  return axios.post("kansas/products/delete_cart", data);
};

export const franchiseOrderService = (data) => {
  return axios.post("kansas/orders/franchise_order", data);
};

export const paymentRequestService = (data) => {
  return axios.post("kansas/payments/payment_request", data);
};

export const listPaymentRequestService = (data, page, size) => {
  return axios.post(
    `kansas/payments/list_payment_request?page=${page}&size=${size}`,
    data
  );
};

export const updatePaymentRequestService = (data) => {
  return axios.post("kansas/payments/update_payment_request", data);
};

export const viewPaymentRequestService = (id) => {
  return axios.get(`kansas/payments/view_payment_request/${id}`);
};

export const deletePaymentRequestService = (id) => {
  return axios.delete(`kansas/payments/delete_payment_request/${id}`);
};

//billList
export const billingService = (data) => {
  return axios.post("kansas/billing/billing", data);
};

export const newBillingService = (data) => {
  return axios.post("kansas/billing/new_billing", data);
};

export const billListService = (data, page, size) => {
  return axios.post(
    `kansas/billing/list_billing?page=${page}&size=${size}`,
    data
  );
};

export const cancelBillService = (data) => {
  return axios.post("kansas/billing/cancel_bill", data);
};

//Dashboard
export const dashboardBalanceService = () => {
  return axios.post("kansas/dashboard/dashboard");
};

export const wallethistoryService = (data, page, size) => {
  return axios.post(
    `kansas/billing/recharge_wallet_history?page=${page}&size=${size}`,
    data
  );
};

export const trackingOrderService = (data) => {
  return axios.post("kansas/dashboard/tracking_order", data);
};

export const viewProfileService = () => {
  return axios.post("kansas/dashboard/view_profile");
};

export const updateProfileService = (data) => {
  return axios.post("kansas/dashboard/update_profile", data);
};

export const listLanguageService = (data) => {
  return axios.post("kansas/dashboard/list_language", data);
};
export const languageSettingService = (data) => {
  return axios.post("kansas/dashboard/language_setting", data);
};

// products
export const updatePriceService = (data) => {
  return axios.post("kansas/products/update_price", data);
};

export const categoryListService = () => {
  return axios.post("kansas/products/list_category");
};

export const subCategoryListService = () => {
  return axios.post("kansas/products/list_sub_category");
};

export const shopCustomersFormService = (data) => {
  return axios.post("kansas/products/shop_customers_form", data);
};
export const productBrandService = (data) => {
  return axios.post("kansas/category/list_brand", data);
};

export const viewCustomerWishlistService = (id) => {
  return axios.get(`kansas/customer_wishlist/view_customer_wishlist/${id}`);
};

export const myorderService = (page, size, data) => {
  return axios.post(
    `kansas/products/ordered_products?page=${page}&size=${size}`,
    data
  );
};

export const viewMyorderService = (page, size, data) => {
  return axios.post(
    `kansas/products/view_ordered_products?page=${page}&size=${size}`,
    data
  );
};

export const addComplaintService = (data) => {
  return axios.post("kansas/complaints/add_complaints", data);
};

export const viewComplaintlistService = (page, size, data) => {
  return axios.post(
    `kansas/complaints/list_complaint_products?page=${page}&size=${size}`,
    data
  );
};

// customer products

export const customerStoreSubcategoryService = () => {
  return axios.post("kansas/products/list_store_sub_category");
};

export const customerCategoryService = (data) => {
  return axios.post("kansas/category/list_store_category", data);
};

export const kansas_productListService = (data, page, size) => {
  return axios.post(
    `kansas/products/store_products?page=${page}&size=${size}`,
    data
  );
};

export const customerBrandService = (data) => {
  return axios.post("kansas/category/list_store_brand", data);
};

export const addCustomerWishlistService = (data) => {
  return axios.post("kansas/customer_wishlist/add_customer_wishlist", data);
};

export const viewStoreProductService = (data) => {
  return axios.post("kansas/products/view_store_products", data);
};

export const deleteCustomerWishlistService = (data) => {
  return axios.post("kansas/customer_wishlist/delete_customer_wishlist", data);
};

// billing
export const viewBillListService = (id) => {
  return axios.get(`kansas/billing/view_bill/${id}`);
};

export const minimumAmountService = () => {
  return axios.post("kansas/orders/purchase_minimum_amount");
};

export const billPdfService = (id) => {
  return axios.get(`kansas/billing/bills_pdf/${id}`);
};

export const failedBillService = (data) => {
  return axios.post("kansas/billing/failed_bill_payment", data);
};

// qr code dowmload

export const QrCodeDownloadService = () => {
  return axios.post("kansas/dashboard/franchise_url");
};

export const UPIQrCodeService = () => {
  return axios.post("kansas/dashboard/upi_qr_code");
};

export const forgotPasswordService = (data) => {
  return axios.post("kansas/forgot_password", data);
};

// emi types
export const emiProviderService = () => {
  return axios.get("kansas/paytm_payment/emi_types");
};

// testimonials
export const testimonialsService = () => {
  return axios.post("kansas/franchise_enquiry/client_testimonials");
};

// store locator

export const storeLocatorService = (data) => {
  return axios.post("kansas/franchise_enquiry/list_franchise", data);
};

// Inventory Control History

export const inventoryControlHistoryService = (data, page, size) => {
  return axios.post(
    `kansas/inventory/inventory_control_history?page=${page}&size=${size}`,
    data
  );
};
export const kansasOrderList = (page, size, data) => {
  return axios.post(
    `kansas/orders/listOrderRequest?page=${page}&size=${size}`,
    data
  );
};
export const kansasViewOrderList = (data) => {
  return axios.post("kansas/orders/viewOrderRequest", data);
};
export const approvalStatusServices = (data) => {
  return axios.post("/kansas/orders/changeApprovalStatus", data);
};
export const getCourierListServices = (data) => {
  return axios.post("/kansas/orders/GetCourierList", data);
};
export const orderStatusChangeServices = (data) => {
  return axios.post("/kansas/orders/changeOrderStatus", data);
};
export const listkansasorderNotification = (page, size, data) => {
  return axios.post(
    `kansas/orders/listNotification?page=${page}&size=${size}`,
    data
  );
};
export const readNotificationList = (data) => {
  return axios.post("/kansas/orders/readNotification", data);
};

//MonthlySTock
export const monthlyStockList = (page, size, data) => {
  return axios.post(
    `kansas/dashboard/listBackupProducts?page=${page}&size=${size}`,
    data
  );
};
//orderDetails
export const getDetails = (data) => {
  return axios.post(`/kansas/dashboard/get_detail`, data);
};
export const ViewBankAccount = (data) => {
  return axios.post(`/kansas/view_bank_account`, data);
};
export const AddBankAccount = (data) => {
  return axios.post("/kansas/add_bank_account", data);
};
export const EditBankAccount = (data) => {
  return axios.post("/kansas/edit_bank_account", data);
};
export const deleteBankAccount = (data) => {
  return axios.post("/kansas/delete_bank_account", data);
};
