import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import Styles from "./ViewBillList.module.css";
import cancelIcon from "../../../Assets/cross.png";
import Profile from "../../../Assets/user_img.png";
import mail from "../../../Assets/email.png";
import phone from "../../../Assets/mbl.png";
import location from "../../../Assets/location_img.png";
import { viewBillListService } from "../../../Services/ApiServices";
import Loader from "../../../SharedComponents/Loader/Loader";
import paymentType from "../../../Assets/payment_type.png";

export default function ViewBillList(props) {
  const [viewData, setViewData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [readmoreId, setReadmoreId] = useState([]);

  useEffect(() => {
    handleViewList();
  }, []);

  const handleViewList = () => {
    setIsLoader(true);
    viewBillListService(props.id)
      .then((res) => {
        setViewData(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast("Something went wrong!!", { type: "error" });
        }
      })
      .finally(() => setIsLoader(false));
  };

  return (
    <div>
      <Loader isLoader={isLoader} />
      <Modal show={props.isShow} onHide={props.onHide} centered size="lg">
        <Modal.Body>
          <div className={Styles.cancelImgParentContainer}>
            <div>
              <p className="fontBold">
                Bill <span className="orange">Details</span>
              </p>
            </div>
            <div className={Styles.cancelImgContainer}>
              <img
                onClick={props.onHide}
                src={cancelIcon}
                alt="cancel"
                className={Styles.cancelImg}
              />
            </div>
          </div>
          <div>
            <div className={Styles.detailUserContainer}>
              <div className={Styles.userLeftContainer}>
                <img className={Styles.img} src={Profile} alt="profile" />
                <p className={Styles.username}>{viewData?.customer_name}</p>
              </div>
              <div className={Styles.userRightContainer}>
                <p className={Styles.username + " text-start"}>
                  Invoice No :
                  <span className={Styles.detail}>{viewData?.bill_no}</span>
                </p>
              </div>
            </div>
            <div className={Styles.detailContainer}>
              <img className={Styles.img} src={phone} alt="profile" />
              <p className={Styles.detail}>{viewData?.mobile_no}</p>
            </div>
            <div className={Styles.detailContainer}>
              <img className={Styles.img} src={mail} alt="profile" />
              <p className={Styles.detail}>
                {viewData?.email ? viewData?.email : "-"}
              </p>
            </div>
            <div className={Styles.detailContainer}>
              <img
                style={{ marginTop: "3px", alignSelf: "flex-start" }}
                className={Styles.location}
                src={location}
                alt="profile"
              />
              <p className={Styles.detail}>
                {viewData?.address}
                {","}
                <br />
                {viewData?.city_name}
                {","}
                <br />
                {viewData?.state_name}
                {viewData?.pincode ? (
                  <>
                    {" "}
                    {","}
                    <br />
                    {viewData?.pincode || ""}
                    {"."}
                  </>
                ) : (
                  "."
                )}
              </p>
            </div>
            <div className={Styles.detailUserContainer}>
              <div className={Styles.userLeftContainer}>
                <img className={Styles.img} src={paymentType} alt="profile" />
                <p className={Styles.username}>
                  {" "}
                  {viewData?.payment_type ? viewData?.payment_type : "-"}
                </p>
              </div>
              <div className={Styles.userRightContainer}>
                <p className={Styles.username + " text-start"}>
                  Bill Status :
                  <span className={Styles.detail + " orange"}>
                    {viewData?.bill_status_type}
                  </span>
                </p>
              </div>
            </div>
          </div>
          {viewData?.purchased_products?.length > 0 ? (
            <div className={Styles.tableParentContainer}>
              <div className={Styles.tableChildContainer + " mt-3"}>
                <table>
                  <thead>
                    <tr>
                      <th className=" label text-center">S.No</th>
                      <th className={Styles.productname + " label text-center"}>
                        Product Name
                      </th>
                      <th className={Styles.th + " label text-center"}>
                        Color
                      </th>
                      <th className={Styles.th + " label text-center"}>Unit</th>
                      <th className={Styles.th + " label text-center"}>Qty</th>
                      <th className=" label text-center">Price</th>
                      <th className=" label text-center">Total</th>
                    </tr>
                  </thead>
                  <tbody className={Styles.data}>
                    {viewData?.purchased_products?.map((e, index) => (
                      <tr>
                        <td className="text-center">{index + 1}</td>
                        <td
                          className={
                            Styles.productname + " contentFont text-center"
                          }
                        >
                          {readmoreId.includes(e.product_id)
                            ? e.product_name
                            : e.product_name.substring(0, 37)}
                          {e.product_name.length > 37 &&
                          !readmoreId.includes(e.product_id)
                            ? "..."
                            : null}
                          <span
                            style={{
                              display:
                                e.product_name.length < 37 ? "none" : "block",
                            }}
                            className={Styles.readmore + " orange"}
                            onClick={() => {
                              if (readmoreId.includes(e.product_id)) {
                                let filterData = readmoreId.filter(
                                  (filterId) => filterId !== e.product_id
                                );
                                setReadmoreId(filterData);
                              } else {
                                setReadmoreId([...readmoreId, e.product_id]);
                              }
                            }}
                          >
                            {readmoreId.includes(e.product_id)
                              ? "Show Less"
                              : "Read More"}
                          </span>
                        </td>
                        <td className={Styles.td}>
                          {e?.product_color.color_name}
                        </td>
                        <td className={Styles.td}>
                          {e?.product_unit.unit_name}
                        </td>
                        <td className={Styles.td}>{e?.quantity}</td>
                        <td className={Styles.td}>
                          {"₹ " + e?.price.toFixed(2)}
                        </td>
                        <td className={Styles.td}>
                          {"₹ " + e?.total_price.toFixed(2)}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={5} />
                      <td className={Styles.td + " fw-bold"}>Total</td>
                      <td className={Styles.td + " fw-bold"}>
                        {"₹ " + viewData?.purchase_amount.toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : null}
        </Modal.Body>
      </Modal>
    </div>
  );
}
