import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  deleteBankAccount,
  updateProfileService,
  ViewBankAccount,
  viewProfileService,
} from "../../../Services/ApiServices";
import Styles from "./ViewProfile.module.css";
import moment from "moment";
import user from "../../../Assets/myprofile/user.svg";
import tag from "../../../Assets/myprofile/tag.svg";
import cash from "../../../Assets/myprofile/cash.svg";
import aadhar from "../../../Assets/myprofile/aadhar.svg";
import card from "../../../Assets/myprofile/card.svg";
import calender from "../../../Assets/myprofile/calender.svg";
import mail from "../../../Assets/myprofile/mail.svg";
import phone from "../../../Assets/myprofile/phone.svg";
import location from "../../../Assets/myprofile/location.svg";
import shop from "../../../Assets/myprofile/shop.svg";
import CITY from "../../../Assets/myprofile/city.svg";
import gender from "../../../Assets/myprofile/gender.svg";
import MyAccountDetails from "../PaymentRequest/MyAccountDetails";
import deleteIcon from "../../../Assets/delete.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
const phoneExp = /^[6,7,8,9]{1}[0-9]{9}$/;
export default function ViewProfile() {
  const [isLoader, setIsLoader] = useState(false);
  useEffect(() => {
    handleViewProfile();
  }, []);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [bankAccountDetails, setBankAccountDetails] = useState();
  const {
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      username: "",
      email: "",
      mbl: "",
      dob: "",
      alt_mbl: "",
      gender: "",
      address: "",
      city: "",
      state: "",
      pincode: "",
      profile: "",
      pan: "",
      aadhar: "",
      gst: "",
      shipping_name: "",
      shipping_email: "",
      shipping_monile_no: "",
      shipping_city: "",
      shipping_state: "",
      shipping_address: "",
      shipping_pincode: "",
      shop_name: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Required"),
      email: Yup.string().required("Required"),
      mbl: Yup.string()
        .matches(phoneExp, "mobile number must start with 9 or 8 or 7 or 6")
        .required("Required"),
      alt_mbl: Yup.string()
        .matches(phoneExp, "mobile number must start with 9 or 8 or 7 or 6")
        .required("Required"),
      gender: Yup.string().required("Required"),
      address: Yup.string().required("Required"),
      city: Yup.string().required("Required"),
      state: Yup.string().required("Required"),
      pincode: Yup.string()
        // .matches(!PINCODE_REGEX, "Enter valid pincode")
        .required("Required"),
      // profile: Yup.string().required("Required"),
      dob: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      setIsLoader(true);
      handleUpdate(values);
    },
  });

  const handleUpdate = (data) => {
    let formData = new FormData();
    formData.append("user_name", data.username);
    formData.append("email", data.email);
    formData.append("gender", parseInt(data.gender));
    formData.append("dob", data.dob);
    formData.append("address", data.address);
    formData.append("alt_mobile", data.alt_mbl);
    formData.append("city", data.city);
    formData.append("state", data.state);
    formData.append("pincode", data.pincode);
    if (typeof data.profile !== "string") {
      formData.append("profile_image", data.profile);
    }
    formData.append("pan_no", data.pan);
    formData.append("aadhaar_no", data.aadhar);
    formData.append("gst_no", data.gst);
    updateProfileService(formData)
      .then((res) => {
        toast("Profile Updated", { type: "success" });
        setIsLoader(false);
        handleViewProfile();
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg);
        } else {
          toast.error("Something went wrong!!");
        }
      })
      .finally(() => setIsLoader(false));
  };
  const handleViewBank = () => {
    setIsLoader(true);
    ViewBankAccount()
      .then((res) => {
        if (res.data.status === 1) {
          setBankAccountDetails(res.data.data);
        } else {
          // toast.error(res.data.msg);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg);
        } else {
          toast.error("Something went wrong!!");
        }
      })
      .finally(() => {
        setIsLoader(false);
      });
  };
  useEffect(() => {
    handleViewBank();
  }, []);
  const handleViewProfile = () => {
    setIsLoader(true);
    viewProfileService()
      .then(({ data }) => {
        setValues({
          ...values,
          username: data.user_name,
          email: data.email,
          mbl: data.mobile_no,
          alt_mbl: data.alternative_mobile_no,
          gender: data.gender.toString(),
          address: data.address,
          city: data.city_name,
          state: data.state_name,
          pincode: data.pincode,
          profile: data.profile_img,
          pan: data.pan_no,
          aadhar: data.aadhaar_no,
          gst: data.gst_no,
          dob:
            data.dob !== ""
              ? moment(data.dob, "DD-MM-YYYY").format("YYYY-MM-DD")
              : moment().format("YYYY-MM-DD"),
          shipping_address: data.shipping_address,
          shipping_city: data.shipping_city_name,
          shipping_email: data.shipping_email,
          shipping_monile_no: data.shipping_monile_no,
          shipping_name: data.shipping_name,
          shipping_pincode: data.shipping_pincode,
          shipping_state: data.shipping_state_name,
          shop_name: data.shop_name,
        });
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg);
        } else {
          toast.error("Something went wrong!!");
        }
      })
      .finally(() => setIsLoader(false));
  };
  const handleDeletebank = () => {
    setIsLoader(true);
    let formData = new FormData();
    formData.append("bank_account_id", bankAccountDetails?.account_id);
    deleteBankAccount(formData)
      .then((res) => {
        if (res.data.status === 1) {
          toast.success(res.data.msg);
          setBankAccountDetails();
          handleViewBank();
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg);
        } else {
          toast.error("Something went wrong!!");
        }
      })
      .finally(() => {
        setIsLoader(false);
      });
  };
  const getGender = (value) => {
    switch (value) {
      case 1:
        return "Male";
      case 2:
        return "Female";

      default:
        return "";
    }
  };
  const handleViewBankdetails = (data) => {
    handleViewBank();
  };
  return (
    <div className={Styles.overallContainer + " container-fluid p-0"}>
      {isLoader ? (
        <div className={Styles.loaderParent}>
          <div className={Styles.loader}></div>
        </div>
      ) : null}
      {isShowPopup ? (
        <MyAccountDetails
          isOpen={isShowPopup}
          handleTableData={handleViewBank}
          onHide={() => setIsShowPopup(false)}
          bankAccountDetails={bankAccountDetails}
        />
      ) : null}
      <form onSubmit={(e) => e.preventDefault()}>
        <div className={Styles.pageWrap}>
          <p className="fontBold pt-3 pb-1 fs-4">
            My <span className="orange">Profile</span>
          </p>
          <div className={Styles.whiteBg}>
            <div className={Styles.cards}>
              <p className={Styles.accText + " label"}>Personal Details</p>
              <div className="row">
                <div className="col-lg-4  col-md-6 col-12 mb-3">
                  <div className="d-flex">
                    <div className={Styles.profileIcon}>
                      <img src={user}></img>
                    </div>
                    <div>
                      <label className="label">Concern Name:</label>
                      <p className={Styles.profileText}>{values.username}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4  col-md-6 col-12 mb-3">
                  <div className="d-flex">
                    <div className={Styles.profileIcon}>
                      <img src={mail}></img>
                    </div>
                    <div>
                      <label className="label">Email:</label>
                      <p className={Styles.profileText}>{values.email}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4  col-md-6 col-12 mb-3">
                  <div className="d-flex">
                    <div className={Styles.profileIcon}>
                      <img src={phone}></img>
                    </div>
                    <div>
                      <label className="label">Mobile Number:</label>
                      <p className={Styles.profileText}>{values.mbl}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4  col-md-6 col-12 mb-3">
                  <div className="d-flex">
                    <div className={Styles.profileIcon}>
                      <img src={phone}></img>
                    </div>
                    <div>
                      <label className="label">Alt Mobile:</label>
                      <p className={Styles.profileText}>{values.alt_mbl}</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4  col-md-6 col-12 mb-3">
                  <div className="d-flex">
                    <div className={Styles.profileIcon}>
                      <img src={calender}></img>
                    </div>
                    <div>
                      <label className="label">DOB:</label>
                      <p className={Styles.profileText}>{values.dob}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4  col-md-6 col-12 mb-3">
                  <div className="d-flex">
                    <div className={Styles.profileIcon}>
                      <img src={tag}></img>
                    </div>
                    <div>
                      <label className="label">State:</label>
                      <p className={Styles.profileText}>{values.state}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4  col-md-6 col-12 mb-3">
                  <div className="d-flex">
                    <div className={Styles.profileIcon}>
                      <img src={CITY}></img>
                    </div>
                    <div>
                      <label className="label">City:</label>
                      <p className={Styles.profileText}>{values.city}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4  col-md-6 col-12 mb-3">
                  <div className="d-flex">
                    <div className={Styles.profileIcon}>
                      <img src={cash}></img>
                    </div>
                    <div>
                      <label className="label">Pincode :</label>
                      <p className={Styles.profileText}>{values.pincode}</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4  col-md-6 col-12 mb-3">
                  <div className="d-flex">
                    <div className={Styles.profileIcon}>
                      <img src={gender}></img>
                    </div>
                    <div>
                      <label className="label">Gender:</label>
                      <p className={Styles.profileText}>
                        {getGender(parseInt(values.gender))}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4  col-md-6 col-12 mb-3">
                  <div className="d-flex">
                    <div className={Styles.profileIcon}>
                      <img src={location}></img>
                    </div>
                    <div>
                      <label className="label">Address:</label>
                      <p className={Styles.profileText}>{values.address}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4  col-md-6 col-12 mb-3">
                  <div className="d-flex">
                    <div className={Styles.profileIcon}>
                      <img src={shop}></img>
                    </div>
                    <div>
                      <label className="label">Shop Name:</label>
                      <p className={Styles.profileText}>{values.shop_name}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={Styles.cards}>
              <div className={Styles.accParentContainer}>
                <p className={Styles.accText + " label"}>Shipping Details</p>
                <div className="row">
                  <div className="col-lg-4  col-md-6 col-12 mb-3">
                    <div className="d-flex">
                      <div className={Styles.profileIcon}>
                        <img src={tag}></img>
                      </div>
                      <div>
                        <label className="label">Shipping Name:</label>
                        <p className={Styles.profileText}>
                          {values.shipping_name}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4  col-md-6 col-12 mb-3">
                    <div className="d-flex">
                      <div className={Styles.profileIcon}>
                        <img src={mail}></img>
                      </div>
                      <div>
                        <label className="label">Shipping Email:</label>
                        <p className={Styles.profileText}>
                          {values.shipping_email}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4  col-md-6 col-12 mb-3">
                    <div className="d-flex">
                      <div className={Styles.profileIcon}>
                        <img src={phone}></img>
                      </div>
                      <div>
                        <label className="label">Shipping Mobile Number:</label>
                        <p className={Styles.profileText}>
                          {values.shipping_monile_no}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4  col-md-6 col-12 mb-3">
                    <div className="d-flex">
                      <div className={Styles.profileIcon}>
                        <img src={location}></img>
                      </div>
                      <div>
                        <label className="label">Shipping Address:</label>
                        <p className={Styles.profileText}>
                          {values.shipping_address}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4  col-md-6 col-12 mb-3">
                    <div className="d-flex">
                      <div className={Styles.profileIcon}>
                        <img src={cash}></img>
                      </div>
                      <div>
                        <label className="label">Shipping State:</label>
                        <p className={Styles.profileText}>
                          {values.shipping_state}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4  col-md-6 col-12 mb-3">
                    <div className="d-flex">
                      <div className={Styles.profileIcon}>
                        <img src={tag}></img>
                      </div>
                      <div>
                        <label className="label">Shipping City:</label>
                        <p className={Styles.profileText}>
                          {values.shipping_city}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4  col-md-6 col-12 mb-3">
                    <div className="d-flex">
                      <div className={Styles.profileIcon}>
                        <img src={tag}></img>
                      </div>
                      <div>
                        <label className="label">Shipping Pincode :</label>
                        <p className={Styles.profileText}>
                          {values.shipping_pincode}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={Styles.cards}>
              <div className={Styles.accParentContainer}>
                <div className={Styles.BankAccountOuter}>
                  <p className={Styles.accText + " label"}>
                    My Bank Account Details
                  </p>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <button
                      className={Styles.addbankDetails}
                      onClick={() => setIsShowPopup(true)}
                    >
                      {bankAccountDetails ? "Edit" : "Add"}
                    </button>
                    {bankAccountDetails ? (
                      <button
                        className={Styles.Deletebtn}
                        onClick={() => handleDeletebank()}
                      >
                        Delete
                      </button>
                    ) : // <OverlayTrigger
                    //   placement="bottom"
                    //   overlay={<Tooltip>Delete</Tooltip>}
                    // >
                    //   <img
                    //     style={{ width: "25px", height: "25px" }}
                    //     onClick={() => {
                    //       handleDeletebank();
                    //       // setViewId(e?.customer_wishlist_id);
                    //       // setIsWishListView(true);
                    //     }}
                    //     className={Styles.del}
                    //     src={deleteIcon}
                    //     alt="view"
                    //   />
                    // </OverlayTrigger>
                    null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4  col-md-6 col-12 mb-3">
                    <div className="d-flex">
                      <div className={Styles.profileIcon}>
                        <img src={card}></img>
                      </div>
                      <div>
                        <label className="label">Bank Name:</label>
                        <p className={Styles.profileText}>
                          {bankAccountDetails?.bank_name}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4  col-md-6 col-12 mb-3">
                    <div className="d-flex">
                      <div className={Styles.profileIcon}>
                        <img src={aadhar}></img>
                      </div>
                      <div>
                        <label className="label">Account Name:</label>
                        <p className={Styles.profileText}>
                          {bankAccountDetails?.account_name}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4  col-md-6 col-12 mb-3">
                    <div className="d-flex">
                      <div className={Styles.profileIcon}>
                        <img src={tag}></img>
                      </div>
                      <div>
                        <label className="label">Branch Name:</label>
                        <p className={Styles.profileText}>
                          {bankAccountDetails?.branch_name}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4  col-md-6 col-12 mb-3">
                    <div className="d-flex">
                      <div className={Styles.profileIcon}>
                        <img src={tag}></img>
                      </div>
                      <div>
                        <label className="label">Account Number:</label>
                        <p className={Styles.profileText}>
                          {bankAccountDetails?.account_no}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4  col-md-6 col-12 mb-3">
                    <div className="d-flex">
                      <div className={Styles.profileIcon}>
                        <img src={tag}></img>
                      </div>
                      <div>
                        <label className="label">IFSC Number:</label>
                        <p className={Styles.profileText}>
                          {bankAccountDetails?.ifsc_code}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={Styles.btnContainer}></div>
              </div>
            </div>
            <div className={Styles.cards}>
              <div className={Styles.accParentContainer}>
                <p className={Styles.accText + " label"}>Account Details</p>
                <div className="row">
                  <div className="col-lg-4  col-md-6 col-12 mb-3">
                    <div className="d-flex">
                      <div className={Styles.profileIcon}>
                        <img src={card}></img>
                      </div>
                      <div>
                        <label className="label">PAN No:</label>
                        <p className={Styles.profileText}>{values.pan}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4  col-md-6 col-12 mb-3">
                    <div className="d-flex">
                      <div className={Styles.profileIcon}>
                        <img src={aadhar}></img>
                      </div>
                      <div>
                        <label className="label">Aadhaar No:</label>
                        <p className={Styles.profileText}>{values.aadhar}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4  col-md-6 col-12 mb-3">
                    <div className="d-flex">
                      <div className={Styles.profileIcon}>
                        <img src={tag}></img>
                      </div>
                      <div>
                        <label className="label">GST No:</label>
                        <p className={Styles.profileText}>{values.gst}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={Styles.btnContainer}></div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
