import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import Styles from "./ViewCustomerProduct.module.css";
import { viewStoreProductService } from "../../../Services/ApiServices";
import emptyImg from "../../../Assets/no_image.svg";
import Loader from "../../../SharedComponents/Loader/Loader";

export default function ViewCustomerProduct(props) {
  let { id } = JSON.parse(sessionStorage.getItem("customerlog"));
  let location = useLocation();
  let isPath = location.pathname;
  const [selectedUnit, setselectedUnit] = useState({
    value: null,
    isError: false,
  });
  const [viewProductData, setViewProductData] = useState(null);
  const [productCount, setProductCount] = useState({
    count: 1,
    errorMsg: false,
  });
  const [isLoader, setIsLoader] = useState(false);
  useEffect(() => {
    handleViewProduct();
  }, []);

  const handleViewProduct = () => {
    setIsLoader(true);
    let formData = new FormData();
    formData.append("franchise_id", id);
    formData.append("product_id", props.product_id);
    viewStoreProductService(formData)
      .then((res) => {
        let tempProductDetails = { ...res.data };
        let tempImagesColors = [...tempProductDetails.product_images].map(
          (item, index) => {
            if (index === 0) {
              return { ...item, isActive: true };
            } else {
              return { ...item, isActive: false };
            }
          }
        );
        tempProductDetails = {
          ...tempProductDetails,
          product_images: tempImagesColors,
        };
        setselectedUnit({
          isError: false,
          value: res.data.product_units ? res.data.product_units[0] : null,
        });
        setViewProductData(tempProductDetails);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong!!", { type: "error" });
        }
      })
      .finally(() => setIsLoader(false));
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const getButtonNameData = () => {
    if (props?.customerSelectedItem?.includes(viewProductData?.product_id)) {
      return { id: 0, name: "Remove" };
    }
    return { id: 1, name: "Add" };
  };

  const handlePressColor = (selectedItem) => {
    let tempProductDetails = { ...viewProductData };
    let alreadySelectedItem = [...tempProductDetails.product_images].find(
      (item) => item.isActive
    );
    if (alreadySelectedItem.code !== selectedItem.code) {
      let tempImagesColors = [...tempProductDetails.product_images].map(
        (item) => {
          if (item.code === selectedItem.code) {
            return { ...item, isActive: true };
          } else {
            return { ...item, isActive: false };
          }
        }
      );
      tempProductDetails = {
        ...tempProductDetails,
        product_images: tempImagesColors,
      };
      setViewProductData(tempProductDetails);
    }
  };

  const getSelectedProductColorItem = () => {
    let tempProductDetails = { ...viewProductData };
    let selectedColorItem = tempProductDetails.product_images?.find(
      (item) => item.isActive
    );
    return selectedColorItem;
  };

  const handleProductCount = (data) => {
    if (data < 1 || isNaN(data)) {
      setProductCount((pre) => ({ ...pre, count: 0, errorMsg: true }));
    } else {
      setProductCount((pre) => ({ ...pre, count: data, errorMsg: false }));
    }
  };
  return (
    <div>
      <Loader isLoader={isLoader} />

      <Modal show={props.show} onHide={props.onHide} size="lg" centered>
        {viewProductData !== null ? (
          <Modal.Body>
            <div className={Styles.cancelImgParentContainer}>
              <div
                onClick={() => props.onHide()}
                className={Styles.cancelImgContainer}
              >
                x
              </div>
            </div>
            <div className={Styles.overallContainer}>
              <div className={Styles.LeftContainer}>
                {viewProductData.product_images?.length > 0 ? (
                  <Carousel
                    autoPlay={false}
                    autoPlaySpeed={5000}
                    arrows={true}
                    infinite={true}
                    responsive={responsive}
                    className="container"
                  >
                    {viewProductData?.product_images
                      .filter((item, index) => item.isActive)[0]
                      ?.image?.map((e, index) => (
                        <div key={index} className={Styles.imgContainer}>
                          <img src={e} alt="test" className={Styles.largeimg} />
                        </div>
                      ))}
                  </Carousel>
                ) : (
                  <img src={emptyImg} alt="empty" />
                )}
              </div>
              <div className={Styles.RightContainer}>
                <p className={Styles.details}>
                  <span className={Styles.productname}>
                    {viewProductData?.product_name}
                  </span>
                </p>
                <p className={Styles.price}>
                  ₹
                  {selectedUnit?.value
                    ? selectedUnit?.value?.price?.toFixed(2) || 0.0
                    : viewProductData?.selling_price.toFixed(2)}{" "}
                  <span className={Styles.oldPrice}>
                    ₹
                    {selectedUnit?.value
                      ? selectedUnit?.value?.franchise_pricing?.toFixed(2) ||
                        0.0
                      : viewProductData?.price.toFixed(2)}
                  </span>{" "}
                </p>
                <p className={Styles.productcode + " orange"}>
                  Product Code :{" "}
                  <span className={Styles.details1 + " text-black"}>
                    {viewProductData?.product_code}
                  </span>
                </p>
                <div className={Styles.unitButtonContainer}>
                  {viewProductData?.product_units &&
                    viewProductData?.product_units.length > 0 && (
                      <>
                        <div className="my-3">
                          <span className="modalHeading orange">Unit : </span>
                          <select
                            // style={{
                            //   display:
                            //     viewProductData.status === 1 ? "none" : null,
                            // }}
                            className={Styles.unitSelectContainer}
                            onChange={(e) => {
                              let tempSelectedUnit =
                                viewProductData?.product_units.find(
                                  (item) =>
                                    parseInt(item.id) ===
                                    parseInt(e.target.value)
                                );
                              if (tempSelectedUnit) {
                                setselectedUnit((pre) => ({
                                  ...pre,
                                  value: tempSelectedUnit,
                                  isError: false,
                                }));
                              } else {
                                setselectedUnit((pre) => ({
                                  ...pre,
                                  value: null,
                                  isError: true,
                                }));
                              }
                            }}
                          >
                            {viewProductData?.product_units.map((item) => {
                              return (
                                <option value={item.id}>{item.unit_id}</option>
                              );
                            })}
                          </select>
                        </div>
                        {selectedUnit.isError ? (
                          <p className={Styles.errorMsg + " text-danger"}>
                            Select Valid Unit
                          </p>
                        ) : null}
                      </>
                    )}
                </div>
                <div>
                  <span className="modalHeading orange">Quantity : </span>
                  <input
                    className={Styles.productCount}
                    type="number"
                    value={productCount.count}
                    defaultValue={1}
                    min="1"
                    onChange={(e) => handleProductCount(e.target.value)}
                  />
                  <button
                    style={{
                      display: !isPath.includes("/kansas_product")
                        ? "none"
                        : null,
                    }}
                    onClick={() => {
                      if (isPath.includes("/kansas_product")) {
                        props?.handleSelectedData({
                          ...viewProductData,
                          selectedUnit: selectedUnit.value,
                          selling_price: selectedUnit.value
                            ? selectedUnit?.value?.price || 0
                            : viewProductData?.selling_price,
                          colorItem: getSelectedProductColorItem(),
                        });
                      }
                    }}
                    className={Styles.customerAddBtn}
                  >
                    {getButtonNameData().name}
                  </button>
                </div>
                {viewProductData?.product_images.length > 0 && (
                  <p className="mt-2 mb-1">Color</p>
                )}
                {viewProductData?.product_images.length > 0 ? (
                  <div className="d-flex">
                    {viewProductData?.product_images?.map((item) => (
                      <div
                        onClick={() => {
                          handlePressColor(item);
                        }}
                        style={{
                          backgroundColor: item?.code,
                          border: item.isActive
                            ? "2px solid #ff7b26"
                            : "2px solid #000",
                        }}
                        className={Styles.color}
                      />
                    ))}
                  </div>
                ) : null}
                <p
                  className={Styles.details}
                  dangerouslySetInnerHTML={{
                    __html: viewProductData?.product_description,
                  }}
                />
              </div>
            </div>
          </Modal.Body>
        ) : null}
      </Modal>
    </div>
  );
}
