import { Form } from "react-bootstrap";
import ReactSelect from "react-select";
import { MobileTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { Input } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import toast from "react-hot-toast";
import Styles from "./FranchiseEnquiry.module.css";
import person from "../../Assets/person.png";
import shoppingBag from "../../Assets/shoppingBag.png";
import black_shopping from "../../Assets/black_shopping.png";
import store from "../../Assets/white_store.png";
import black_store from "../../Assets/black_store.png";
import white_ok from "../../Assets/white_ok.png";
import black_ok from "../../Assets/black_ok.png";
import success from "../../Assets/success.png";
import {
  cityService,
  personalDetailsService,
  stateService,
} from "../../Services/ApiServices";

let EMAIL_REGEX = new RegExp(
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const phoneExp = /^[6,7,8,9]{1}[0-9]{9}$/;

export default function FranchiseEnquiry() {
  const [formState, setFormState] = useState(0);
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [selectRef, setSelectRef] = useState({ state: "", city: "" });

  useEffect(() => {
    stateService()
      .then((res) => setState(res.data))
      .catch((err) => {});
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      dob: "",
      address: "",
      currentOccupation: "",
      experience: "0-1",
      gender: "",
      nationality: "Indian",
      mobile: "",
      email: "",
      city: "",
      state: "",
      convinentCall: "",
      referedBy: "",
      timeFrame: "0",
      storePrefered: "TIER 1 (100 SQ FT TO 150 SQ FT)",
      storeSituated: "",
      floor: "",
      others: "",
    },
    validate: () => {
      let err = {};
      switch (formState) {
        case 0:
          {
            if (formik.values.name === "") {
              err.name = "Required";
            }
            if (formik.values.dob === "") {
              err.dob = "Required";
            }
            if (formik.values.address === "") {
              err.address = "Required";
            }
            if (formik.values.currentOccupation === "") {
              err.currentOccupation = "Required";
            }
            if (formik.values.experience === "") {
              err.experience = "Required";
            }
            if (formik.values.gender === "") {
              err.gender = "Required";
            }
            if (formik.values.nationality === "") {
              err.nationality = "Required";
            }
            if (!phoneExp.test(formik.values.mobile)) {
              err.mobile = "mobile number must start with 9 or 8 or 7 or 6";
            }
            if (formik.values.mobile === "") {
              err.mobile = "Required";
            }
            if (!EMAIL_REGEX.test(formik.values.email)) {
              err.email = "Enter Valid Email Address";
            }
            if (formik.values.email === "") {
              err.email = "Required";
            }
          }
          break;
        case 1:
          {
            if (formik.values.city === "") {
              err.city = "Required";
            }
            if (formik.values.state === "") {
              err.state = "Required";
            }
            if (formik.values.storePrefered === "") {
              err.storePrefered = "Required";
            }
            if (formik.values.convinentCall === "") {
              err.convinentCall = "Required";
            }
            if (formik.values.referedBy === "") {
              err.referedBy = "Required";
            }
          }
          break;
        case 2: {
          if (formik.values.storeSituated === "") {
            err.storeSituated = "Required";
          }
          if (formik.values.floor === "") {
            err.floor = "Required";
          }
        }
      }
      return err;
    },
    validateOnChange: false,

    onSubmit: (values) => {
      if (formState === 0) {
        setFormState(1);
      } else if (formState === 1) {
        setFormState(2);
      } else if (formState === 2) {
        handleData(values);
        setLoader(true);
      }
    },
  });

  const handleCity = (data) => {
    let formData = new FormData();
    formData.append("state_id", parseInt(data));
    cityService(formData)
      .then((res) => setCity(res.data))
      .catch((err) => {});
  };

  const handlePrevios = () => {
    setFormState(formState - 1);
  };

  const handleData = (data) => {
    let formData = new FormData();
    formData.append("name", data.name);
    formData.append("dob", data.dob);
    formData.append("email", data.email);
    formData.append("address", data.address);
    formData.append("current_occupation", data.currentOccupation);
    formData.append("experience", data.experience);
    formData.append("gender", parseInt(data.gender));
    formData.append("nationality", data.nationality);
    formData.append("mobile_no", parseInt(data.mobile));
    formData.append("city", parseInt(data.city));
    formData.append("state", parseInt(data.state));
    formData.append("store_preferred", data.storePrefered);
    formData.append(
      "convinent_call_time",
      moment(data.convinentCall).format("hh:mm:A")
    );
    formData.append("refered_by", data.referedBy);
    formData.append(
      "time_frame_on_starting_business",
      parseInt(data.timeFrame)
    );
    formData.append("store_situated_at", data.storeSituated);
    formData.append("floor", data.floor);
    personalDetailsService(formData)
      .then((res) => {
        if (res.status === 200) {
          setFormState(3);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast("Something went wrong!!");
        }
      })
      .finally(() => setLoader(false));
  };

  const customStyles = {
    control: () => ({
      // none of react-select's styles are passed to <Control />
      backgroundColor: "black",
      border: "none",
      width: "100%",
      ":hover": {
        borderColor: "black",
      },
    }),
    indicatorsContainer: () => ({
      display: "none",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "white",
    }),
    input: (provided, state) => ({
      ...provided,
      color: "white",
    }),
  };

  const [tempState, setTempState] = useState(false);

  return (
    <div id="franchise">
      {loader ? (
        <div className={Styles.loaderParent}>
          <div className={Styles.loader}></div>
        </div>
      ) : null}
      <div className="d-flex justify-content-center">
        <h1 className="text-white kstoreFont">
          Franchise <span className="orange">Enquiry</span>
        </h1>
      </div>

      <div>
        <ul className={Styles.progressbar}>
          <div
            className={Styles.liContainer}
            style={{ backgroundColor: "#ff7b26" }}
          >
            <li>
              <img className={Styles.li_img} src={person} alt="person" />
            </li>
            <span style={{ color: "#ff7b26" }} className={Styles.positonName}>
              Personal
            </span>
          </div>
          <div className={Styles.hr} style={{ backgroundColor: "#ff7b26" }} />
          <div
            className={Styles.liContainer}
            style={
              formState === 1 || formState === 2 || formState === 3
                ? { backgroundColor: "#ff7b26" }
                : { backgroundColor: "lightgray" }
            }
          >
            <li>
              <img
                className={Styles.li_shopping}
                src={
                  formState === 1 || formState === 2 || formState === 3
                    ? shoppingBag
                    : black_shopping
                }
                alt="person"
              />
            </li>
            <span
              style={
                formState === 1 || formState === 2 || formState === 3
                  ? { color: "#ff7b26" }
                  : { color: "lightgray" }
              }
              className={Styles.positonName}
            >
              Business
            </span>
          </div>
          <div
            className={Styles.hr}
            style={
              formState === 1 || formState === 2 || formState === 3
                ? { backgroundColor: "#ff7b26" }
                : { backgroundColor: "lightgray" }
            }
          />
          <div
            className={Styles.liContainer}
            style={
              formState === 2 || formState === 3
                ? { backgroundColor: "#ff7b26" }
                : { backgroundColor: "lightgray" }
            }
          >
            <li>
              <img
                className={Styles.li_shopping}
                src={formState === 2 || formState === 3 ? store : black_store}
                alt="store"
              />
            </li>
            <span
              style={
                formState === 2 || formState === 3
                  ? { color: "#ff7b26" }
                  : { color: "lightgray" }
              }
              className={Styles.positionStore}
            >
              Store
            </span>
          </div>
          <div
            className={Styles.hr}
            style={
              formState === 2 || formState === 3
                ? { backgroundColor: "#ff7b26" }
                : { backgroundColor: "lightgray" }
            }
          />
          <div
            className={Styles.liContainer}
            style={
              formState === 3
                ? { backgroundColor: "#ff7b26" }
                : { backgroundColor: "lightgray" }
            }
          >
            <li>
              <img
                className={Styles.li_shopping}
                src={formState === 3 ? white_ok : black_ok}
                alt="person"
              />
            </li>
            <span
              style={
                formState === 3 ? { color: "#ff7b26" } : { color: "lightgray" }
              }
              className={Styles.positonName}
            >
              Complete
            </span>
          </div>
        </ul>
      </div>

      <div className="container">
        <form onSubmit={(e) => e.preventDefault()}>
          {formState === 0 ? (
            <div>
              <div className={Styles.formFirst}>
                <div className={Styles.firstInputContainer}>
                  <label className="text-white">Name*</label>
                  <div className={Styles.inputContainer} id="name">
                    <input
                      name="name"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      className={Styles.input}
                      type="text"
                    />
                  </div>
                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-danger fs-6">{formik.errors.name}</div>
                  ) : null}
                </div>

                <div className={Styles.firstInputContainer}>
                  <label className="text-white">DOB *</label>

                  <div className={Styles.AddressinputContainer}>
                    <input
                      onChange={formik.handleChange}
                      value={formik.values.dob}
                      className={Styles.input}
                      name="dob"
                      type="date"
                      id="dob"
                    />
                  </div>
                  {formik.touched.dob && formik.errors.dob ? (
                    <div className="text-danger fs-6">{formik.errors.dob}</div>
                  ) : null}
                </div>
              </div>

              <label className="text-white mt-5">Address*</label>
              <div className={Styles.AddressinputContainer}>
                <input
                  onChange={formik.handleChange}
                  value={formik.values.address}
                  name="address"
                  className={Styles.input}
                  type="text"
                />
              </div>
              {formik.touched.address && formik.errors.address ? (
                <div className="text-danger fs-6">{formik.errors.address}</div>
              ) : null}

              <div className={Styles.secondInputContainer + " mt-4"}>
                <div className={Styles.thirdChildContainer}>
                  <label className="text-white">Current Occupation*</label>
                  <div className={Styles.AddressinputContainer}>
                    <input
                      name="currentOccupation"
                      className={Styles.input}
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.currentOccupation}
                    />
                  </div>
                  {formik.touched.currentOccupation &&
                  formik.errors.currentOccupation ? (
                    <div className="text-danger fs-6">
                      {formik.errors.currentOccupation}
                    </div>
                  ) : null}
                </div>

                <div className={Styles.thirdChildContainer}>
                  <label className="text-white">Years of experience*</label>
                  <div className={Styles.inputContainer}>
                    <Form.Select
                      className={Styles.select + " p-0"}
                      aria-label="Default select example"
                      name="experience"
                      onChange={formik.handleChange}
                      value={formik.values.experience}
                    >
                      <option value="0-1">0-1</option>
                      <option value="1-2">1-2</option>
                      <option value="2-5">2-5</option>
                      <option value="5-10">5-10</option>
                      <option value="10-15">10-15</option>
                    </Form.Select>
                  </div>
                  {formik.touched.experience && formik.errors.experience ? (
                    <div className="text-danger fs-6">
                      {formik.errors.experience}
                    </div>
                  ) : null}
                </div>
                <div className={Styles.thirdChildContainer}>
                  <label className="text-white">Gender*</label>
                  <div className={Styles.radioInput + " d-flex"}>
                    <div className={Styles.radioContainer}>
                      <input
                        onChange={formik.handleChange}
                        className={Styles.radioBtn + " align-self-center"}
                        type="radio"
                        value="1"
                        name="gender"
                        checked={formik.values.gender === "1" ? true : false}
                      />
                      <label
                        className={Styles.radioLabel + " text-white"}
                        for="age1"
                      >
                        Male
                      </label>
                    </div>
                    <div className={Styles.radioContainer}>
                      <input
                        onChange={formik.handleChange}
                        value="2"
                        type="radio"
                        name="gender"
                        checked={formik.values.gender === "2" ? true : false}
                        className={Styles.radioBtn + " align-self-center"}
                      />
                      <label className={Styles.radioLabel + " text-white"}>
                        Female
                      </label>
                    </div>
                    {formik.touched.gender && formik.errors.gender ? (
                      <div className="text-danger fs-6">
                        {formik.errors.gender}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className={Styles.fourthInputContainer}>
                <div className={Styles.thirdChildContainer}>
                  <label className="text-white">Nationality*</label>
                  <div className={Styles.AddressinputContainer}>
                    <Form.Select
                      className={Styles.select + " p-0"}
                      aria-label="Default select example"
                      name="nationality"
                      onChange={formik.handleChange}
                      value={formik.values.nationality}
                    >
                      <option value="Indian">Indian</option>
                      <option value="NRI">NRI</option>
                    </Form.Select>
                  </div>
                  {formik.touched.nationality && formik.errors.nationality ? (
                    <div className="text-danger fs-6">
                      {formik.errors.nationality}
                    </div>
                  ) : null}
                </div>
                <div className={Styles.thirdChildContainer}>
                  <div>
                    <label className="text-white">Mobile Number*</label>
                    <div className={Styles.AddressinputContainer}>
                      <input
                        onChange={formik.handleChange}
                        value={formik.values.mobile}
                        name="mobile"
                        className={Styles.input}
                        type="text"
                        maxLength="10"
                        onKeyPress={(e) => {
                          if (!(e.key === "0" || parseInt(e.key))) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                    {formik.touched.mobile && formik.errors.mobile ? (
                      <div className="text-danger fs-6">
                        {formik.errors.mobile}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className={Styles.thirdChildContainer}>
                  <label className="text-white">Email*</label>
                  <div className={Styles.AddressinputContainer}>
                    <input
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      name="email"
                      className={Styles.input}
                      type="text"
                    />
                  </div>
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-danger fs-6">
                      {formik.errors.email}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : formState === 1 ? (
            <div>
              <div className={Styles.businessContainer}>
                <label className={Styles.label + " text-white"}>
                  Preferred Business Location*
                </label>
                <div
                  className={
                    Styles.businessFirstContainer +
                    " w-100 justify-content-evenly"
                  }
                >
                  <div className={Styles.parentStateContainer}>
                    <div className={Styles.state}>
                      <ReactSelect
                        className="basic-single"
                        classNamePrefix="select"
                        name="color"
                        placeholder="Select State..."
                        // defaultInputValue={selectRef.state}
                        isClearable={false}
                        value={state.find(
                          (e) => e.state_id === formik.values.state
                        )}
                        styles={customStyles}
                        options={state}
                        getOptionLabel={(e) => e.state_name}
                        getOptionValue={(e) => e.state_name}
                        onChange={(e) => {
                          handleCity(e.state_id);
                          formik.setFieldValue("state", e.state_id);
                          setSelectRef({
                            ...selectRef,
                            state: e.state_name,
                            city: "",
                          });

                          formik.setFieldValue("city", "");
                        }}
                      />
                    </div>
                    {formik.touched.state && formik.errors.state ? (
                      <div className="text-danger fs-6">
                        {formik.errors.state}
                      </div>
                    ) : null}
                  </div>
                  <div className={Styles.parentStateContainer}>
                    <div className={Styles.city}>
                      <ReactSelect
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder="Select City..."
                        name="color"
                        // defaultInputValue={selectRef.city}
                        value={city.find(
                          (e) => e.cities_id === formik.values.city
                        )}
                        isClearable={false}
                        styles={customStyles}
                        options={city}
                        getOptionLabel={(e) => e.cities_name}
                        getOptionValue={(e) => e.cities_name}
                        onChange={(e) => {
                          setSelectRef({ ...selectRef, city: e.cities_name });
                          formik.setFieldValue("city", e.cities_id);
                        }}
                        key={formik.values.city !== "" ? Math.random() : "123"}
                      />
                    </div>
                    {formik.touched.city && formik.errors.city ? (
                      <div className="text-danger fs-6">
                        {formik.errors.city}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className={Styles.businessSecondContainer + " mt-5 w-100"}>
                <div className={Styles.thirdChildContainer}>
                  <label className="text-white">Area of store preferred*</label>
                  <div className={Styles.inputContainer}>
                    <Form.Select
                      className={Styles.select + " p-0"}
                      aria-label="Default select example"
                      name="storePrefered"
                      onChange={formik.handleChange}
                    >
                      <option value="TIER 1 (100 SQ FT TO 150 SQ FT)">
                        TIER 1 (100 SQ FT TO 150 SQ FT)
                      </option>
                      <option value="TIER 2 (150 SQ FT TO 250 SQ FT)">
                        TIER 2 (150 SQ FT TO 250 SQ FT)
                      </option>
                      <option value="TIER 3 (250 SQ FT TO 350 SQ FT)">
                        TIER 3 (250 SQ FT TO 350 SQ FT)
                      </option>
                      <option value="TIER 4 (350 SQ FT TO 500 SQ FT)">
                        TIER 4 (350 SQ FT TO 500 SQ FT)
                      </option>
                    </Form.Select>
                  </div>
                  {formik.touched.storePrefered &&
                  formik.errors.storePrefered ? (
                    <div className="text-danger fs-6">
                      {formik.errors.storePrefered}
                    </div>
                  ) : null}
                </div>
                <div className="d-flex w-100">
                  <div
                    className={
                      Styles.businessContainer + " w-100 justify-content-evenly"
                    }
                  >
                    <div className={Styles.thirdChildContainer}>
                      <label className="text-white">
                        Convenient time to call*
                      </label>
                      <div className={Styles.timeContainer}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileTimePicker
                            value={formik.values.convinentCall}
                            onChange={(newValue) => {
                              formik.setFieldValue("convinentCall", newValue);
                            }}
                            open={tempState}
                            onClose={() => setTempState(false)}
                            renderInput={(params) => (
                              <Input
                                {...params}
                                value="gknjn"
                                disableUnderline={true}
                                onClick={() => setTempState(true)}
                                style={{
                                  color: "white",
                                  borderBottom: "1px solid white",
                                }}
                                fullWidth
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                      {formik.touched.convinentCall &&
                      formik.errors.convinentCall ? (
                        <div className="text-danger fs-6">
                          {formik.errors.convinentCall}
                        </div>
                      ) : null}
                    </div>
                    <div className={Styles.thirdChildContainer}>
                      <label className="text-white">Referred by*</label>
                      <div className={Styles.AddressinputContainer}>
                        <input
                          name="referedBy"
                          className={Styles.input}
                          type="text"
                          value={formik.values.referedBy}
                          onChange={formik.handleChange}
                        />
                      </div>
                      {formik.touched.referedBy && formik.errors.referedBy ? (
                        <div className="text-danger fs-6">
                          {formik.errors.referedBy}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  Styles.businessContainer +
                  " w-100 justify-content-between mt-5"
                }
              >
                <div className={Styles.businessCheckbox + " d-flex"}>
                  <label className={Styles.businessLabel + " text-white"}>
                    Time frame on starting Business
                  </label>

                  <div className="form-check w-100">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                      name="timeFrame"
                      id="flexCheckChecked"
                      checked={formik.values.timeFrame === "0" ? false : true}
                      // checked={formik.values.timeFrame === "1" ? true : false}
                      onChange={(e) => {
                        if (formik.values.timeFrame === "0") {
                          formik.setFieldValue("timeFrame", e.target.value);
                        } else {
                          formik.setFieldValue("timeFrame", "0");
                        }
                      }}
                    />

                    <label className="form-check-label text-white">
                      Immediate
                    </label>
                  </div>
                  {formik.touched.timeFrame && formik.errors.timeFrame ? (
                    <div className="text-danger fs-6">
                      {formik.errors.timeFrame}
                    </div>
                  ) : null}
                </div>
                <div className="w-100">
                  <div className={Styles.othersContainer}>
                    <label className="text-white">Others</label>
                    <div className={Styles.AddressinputContainer}>
                      <input
                        value={formik.values.others}
                        className={Styles.input}
                        type="text"
                        name="others"
                        onChange={(e) =>
                          formik.setFieldValue("others", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : formState === 2 ? (
            <div>
              <div>
                <p className="text-white mb-0">Store situated at*</p>
                <div className={Styles.storeCheckbox + " mt-3"}>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="ownbuilding"
                      name="storeSituated"
                      onChange={(e) =>
                        formik.setFieldValue("storeSituated", e.target.value)
                      }
                      checked={formik.values.storeSituated === "ownbuilding"}
                      id="flexCheckDefault"
                    />
                    <label className="form-check-label text-white">
                      Own Building
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className={Styles.checkbox + " form-check-input"}
                      type="checkbox"
                      id="flexCheckChecked"
                      value="rental"
                      name="storeSituated"
                      onChange={(e) =>
                        formik.setFieldValue("storeSituated", e.target.value)
                      }
                      checked={formik.values.storeSituated === "rental"}
                    />
                    <label className="form-check-label text-white">
                      Rental
                    </label>
                  </div>
                </div>
                {formik.touched.storeSituated && formik.errors.storeSituated ? (
                  <div className="text-danger fs-6">
                    {formik.errors.storeSituated}
                  </div>
                ) : null}
              </div>
              <div>
                <p className="text-white mb-0 mt-3">Floor*</p>
                <div className={Styles.storeFloorCheckbox + " mt-3"}>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="ground"
                      id="flexCheckDefault"
                      name="floor"
                      onChange={(e) =>
                        formik.setFieldValue("floor", e.target.value)
                      }
                      checked={formik.values.floor === "ground"}
                    />
                    <label className="form-check-label text-white">
                      Ground
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className={Styles.checkbox + " form-check-input"}
                      type="checkbox"
                      name="floor"
                      value="first"
                      id="flexCheckChecked"
                      onChange={(e) =>
                        formik.setFieldValue("floor", e.target.value)
                      }
                      checked={formik.values.floor === "first"}
                    />
                    <label className="form-check-label text-white">First</label>
                  </div>
                  <div className="form-check">
                    <input
                      className={Styles.checkbox + " form-check-input"}
                      type="checkbox"
                      name="floor"
                      value="second"
                      id="flexCheckChecked"
                      onChange={(e) =>
                        formik.setFieldValue("floor", e.target.value)
                      }
                      checked={formik.values.floor === "second"}
                    />
                    <label className="form-check-label text-white">
                      Second
                    </label>
                  </div>
                </div>
                {formik.touched.floor && formik.errors.floor ? (
                  <div className="text-danger fs-6">{formik.errors.floor}</div>
                ) : null}
              </div>
            </div>
          ) : formState === 3 ? (
            <div>
              <p className={Styles.success}>success!!!</p>
              <div className="text-center">
                <img src={success} alt="success" />
              </div>
            </div>
          ) : null}
          <div className="text-center mt-5">
            {formState > 0 ? (
              <button
                style={formState === 3 ? { display: "none" } : null}
                // onClick={handlePrevios}
                onMouseDown={handlePrevios}
                // autoFocus={false}
                className={Styles.previousBtn}
              >
                Previous
              </button>
            ) : null}
            <button
              style={formState === 3 ? { display: "none" } : null}
              onClick={() => {
                formik.handleSubmit();
              }}
              className={Styles.nextBtn}
            >
              {formState === 2 ? "Submit" : "Next"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
